import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngb-modal';
import { NgxHighlightWordsModule } from 'ngx-highlight-words';

//For other chart
import { ChartModule } from 'angular-highcharts';

//For gantt
import { HighchartsChartModule } from 'highcharts-angular';

import { NgxCurrencyModule } from 'ngx-currency';

import { environment } from '@env/environment';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { LandingModule } from './landing/landing.module';
import { HomeModule } from './home/home.module';
import { NgbdModalStackedModule } from './home/modal-stacked.module';

import { ShellModule } from './shell/shell.module';
import { AboutModule } from './about/about.module';
import { TestModule } from './test/test.module';
import { UserAnnotModule } from './user-annot/user-annot.module';

import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
//import { UserAnnotComponent } from './user-annot/user-annot.component';

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    ModalModule,
    ChartModule,
    HighchartsChartModule,
    NgxCurrencyModule,
    NgxHighlightWordsModule,
    CoreModule,
    SharedModule,
    ShellModule,
    AboutModule,
    HomeModule,
    NgbdModalStackedModule,
    TestModule,
    UserAnnotModule,
    LandingModule,
    LoginModule,
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
