import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserAnnotRoutingModule } from './user-annot-routing.module';
import { UserAnnotComponent } from './user-annot.component';
import { UserAnnotService } from './user-annot.service';

@NgModule({
  imports: [
       CommonModule,
       TranslateModule,
       UserAnnotRoutingModule,
       FormsModule,
       ReactiveFormsModule,
       NgbModule
   ],
   declarations: [UserAnnotComponent],
   providers: [UserAnnotService] 
})
export class UserAnnotModule {}
