import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UserAnnotService {
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0'
      })
    };
  }

  getAggrUserAnnot(data_source: string = 'pubs'): Observable<any> {
    const salt = (new Date()).getTime();	   
    let url = '/v3/platform/user_aggr_annot?salt='+salt+'&data_source=' + data_source;
    console.log('get url: ' + url);
    return this.httpClient.get(url);
  }

}
