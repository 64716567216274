import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgForm } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ModalManager } from 'ngb-modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CredentialsService } from '@app/core';
import { UserAnnotService } from './user-annot.service';

import { NgbdSortableHeader, SortEvent } from './sortable.directive';


@Component({
  selector: 'app-user-annot',
  templateUrl: './user-annot.component.html',
  styleUrls: ['./user-annot.component.scss'],
  providers: [NgbModalConfig, NgbModal, NgbActiveModal, DecimalPipe]

})
export class UserAnnotComponent implements OnInit {

  pubs: any = []; 
  pubs_full: any = [];
  pubs_stat: any = {};
  pubmed_pubs: any = [];
  pubmed_pubs_full: any = [];
  pubmed_pubs_stat: any = {};

  loaders: any = {pubs: 0, pubmed_pubs: 0};
  pagination: any = {pubs: 0, pubmed_pubs: 0};
  page = 1;
  currentPage = 1;
  pageSize: number = 10;
  listSizes = [{ id: 10, name: '10' }, { id: 25, name: '25' }, { id: 50, name: '50' }, { id: 100, name: '100' }];

  filter_pubs: FormControl;
  filter_pubmed_pubs: FormControl;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private modalService: NgbModal,
              private activeModal: NgbActiveModal,
              private credentialsService: CredentialsService,
              private authenticationService: AuthenticationService,
              private userAnnotService: UserAnnotService
	     ){ }

  ngOnInit() {

      //Search for pubs	    
      this.create_filter('filter_pubs', 'pubs');
      this.create_filter('filter_pubmed_pubs', 'pubmed_pubs'); 
      this.getUserAnnot('pubs');
      this.getUserAnnot('pubmed_pubs'); 

  }

  create_filter(filter_name: string, dataset: string){
     this[filter_name] = new FormControl('');
      this[filter_name].valueChanges.subscribe((search_text: string) => {
        if (search_text.length > 2) {
          this[dataset] = this.search(search_text, this[dataset], 'appl_id');
        } else {
          this[dataset] = JSON.parse(JSON.stringify(this[dataset + '_full']));
        }
      });
  }

  getUserAnnot(data_source: string = ''){
      console.log('Run getUserAnnot');  
      this.loaders[data_source] = 1;	   
      this.userAnnotService.getAggrUserAnnot(data_source)
              .pipe(finalize(() => { this.loaders[data_source] = 0; }))
              .subscribe((userData: any) => {
		                        console.log(userData);
					let data =  JSON.parse(userData);
                                        console.log(data);                                      
                                        this[data_source] = data['data'];
					this[data_source + '_full'] = data['data'];
					this[data_source + '_stat'] = data['stat'];

					//console.log(this[data_source]);
                                        //console.log(this.pubs);
	      });
  }

 search(text: string, data: any, column: string = '', exact: boolean = false) {
    let copyData = JSON.parse(JSON.stringify(data));
    const term = text.toLowerCase();
    if (column.length > 0) {
      console.log('Search in column: ' + column);
      return copyData.filter((comp: any) => {
        const term = text.toLowerCase();
        if(exact){
            return comp[column].toLowerCase() === term;
        }else{
            return comp[column].toLowerCase().includes(term);
        }
      });

    } else {
      console.log('Search in all columns');
      return copyData.filter((obj: any) => {
        return Object.keys(obj).reduce((acc, curr) => {
          //console.log(curr);
          return acc || (this.isString(obj[curr]) && obj[curr].toLowerCase().includes(term));
        }, false);
      });
    }
  }

 isString(x: any) {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  onTabChange(e: any){
      console.log(e);
      if(e && e.nextId){
          if(e.nextId == 'kolDiff' && !this.pubmed_pubs.length){
              //this.loadPubsDiff(this.appl_selected.appl_id);
              console.log('Load next');
	  }
      }
   }

 selectMaxSize($event: any) {
    console.log($event);
    //this.samplesPageSize = this.listSizes[$event];
    //console.log("Selected: " + this.samplesPageSize + " rows");
    this.pageSize = $event;
  }

}
