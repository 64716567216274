import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngb-modal';
import { NgxHighlightWordsModule } from 'ngx-highlight-words';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HomeService } from './home.service';

import { NgbdSortableHeader } from './sortable.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    //NgbModal,
    ModalModule,
    SharedModule,
    NgxHighlightWordsModule,
    HomeRoutingModule
  ],
  declarations: [HomeComponent, NgbdSortableHeader],
  providers: [HomeService]
})
export class HomeModule {}
