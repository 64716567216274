import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HomeService {
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0'
      })
    };
  }

  getTerm(search_text: string): Observable<any> {
    let url = '/v3/platform/search?q=' + search_text;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getTermBySync(search_text: string, search_type: string): Observable<any> {
    let url = '/v3/platform/synsearch?q=' + search_text + '&type=' + search_type;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getDisease(disease_id: string): Observable<any> {
    let url = '/v3/platform/diseases?disease_id=' + disease_id;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  //s - use synonyms  
  getPubs(term: string, w: string, s: number = 1): Observable<any>{
     const salt = (new Date()).getTime();	  
     let url = '/v3/platform/pubs?salt=' + salt + '&q=' + term + '&w=' + w + '&s=' + s;
     console.log('get url: ' + url);
     return this.httpClient.get(url);
  }

  getPubmedPubs(term: string): Observable<any>{
     const salt = (new Date()).getTime();
     let url = '/v3/platform/pubmed_pubs?salt=' + salt + '&q=' + term;
     console.log('get url: ' + url);
     return this.httpClient.get(url);
  }

  getPubmedDiff(term: string, w: string, s: number = 1): Observable<any>{
     const salt = (new Date()).getTime();
     let url = '/v3/platform/pubs_diff?salt=' + salt + '&q=' + w + '&appl_id=' + term;
     console.log('get url: ' + url);
     return this.httpClient.get(url);
  }

  getPub(pm_id: string, appl_id: string = ''): Observable<any>{
     let url = '/v3/platform/pub/' + pm_id;
     if(appl_id){
         url += '?appl_id=' + appl_id;  
     }

     console.log('get url: ' + url);
     return this.httpClient.get(url);
  } 

  setUserAnnot(formData: any): Observable<any> {
    let url = '/v3/platform/user_annot';
    console.log('get url: ' + url);
    return this.httpClient.post(url, formData);
  }

  setUserSynNote(formData: any): Observable<any> {
    let url = '/v3/platform/syn_note';
    console.log('get url: ' + url);
    return this.httpClient.post(url, formData);
  }

  getUserSynNotes(term: string, w: string): Observable<any> {
    const salt = (new Date()).getTime();	  
    let url = '/v3/platform/syn_note?salt=' + salt + '&q=' + term + '&w=' + w;
    console.log('get url: ' + url);
    return this.httpClient.get(url);
  }

  setUserPubNote(formData: any): Observable<any> {
    let url = '/v3/platform/pub_note';
    console.log('get url: ' + url);
    return this.httpClient.post(url, formData);
  }

  delUserPubNote(formData: any): Observable<any> {
    let url = '/v3/platform/pub_note';
    console.log('get url: ' + url);
    return this.httpClient.post(url, formData);
  }

  getUserPubNotes(term: string): Observable<any>{
     const salt = (new Date()).getTime();
     let url = '/v3/platform/pub_note?salt=' + salt + '&q=' + term;
     console.log('get url: ' + url);
     return this.httpClient.get(url);
  }

}
