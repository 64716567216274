import { Component, ElementRef, QueryList, ViewChildren, ViewChild, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgForm } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ModalManager } from 'ngb-modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CredentialsService } from '@app/core';
import { HomeService } from './home.service';

import { NgbdSortableHeader, SortEvent } from './sortable.directive';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbModalConfig, NgbModal, NgbActiveModal, DecimalPipe]
})

export class HomeComponent implements OnInit {
  isLogged = false;
  closeResult: string;
  confirmClearModal: any;
  credentials: any = {};
  quote: string | undefined;
  error: string | undefined;
  noteFormError: string = '';
  noteForm!: FormGroup;
  pubnoteForm: FormGroup;
  filterForm!: FormGroup;
  isLoading = false;
  isTermSearchLoading = false;
  single_term_search_mode: string = "synonym";
  synnotes: any = []; 
  synonyms_stat: any = [];

  pubs: any = {total: 0, data: []};
  pubmed_pubs: any = {total: 0, data: []};

  select_pm_id: string = '';
  select_pm_source_type: string = 'pubs';
  pub: any = {title: '', abstract: '', pm_id: '', _id: ''};
  pub_index: number = null;
  show_pubs = false; 
  show_pubmed_pubs = false;

  loaders: any = {pubs: 0, pub: 0, pubmed_pubs: 0};
  pagination: any = {pubs: 1, pubmed_pubs: 1};

  adv_term_search: string = "";
  adv_term_search_index: any = null; 
  isSamplesLoading = false;
  isUserSamplesLoading = false;
  isWarningsLoading = false;
  showfull = false;
  show = false;
  show_synonyms = false;
  autocomplete = false;
  progress = false;
  appl_id_selected: string = '';
  appl_selected: any = {id: '', appl_id: '', type: '', search: '', synonyms: []};
  syn_selected: string = '';
  note_syn_selected: string = '';
  search_words: any = []; 
  term_search_str: string = '';
  term_data: any = {hits: 0, diseases: [], targets: []};
  term_search: string = '';
  //term_data: any = [];

  diseases_data: any = [];
  tissues_data: any = [];

  has_results = false;

  history: any = [];

  mf_column_filter_values: any = [];
  selectedColumnFilter: any = '';

  page = 1;
  currentPage = 1;
  pageSize: number = 10;
  listSizes = [{ id: 10, name: '10' }, { id: 25, name: '25' }, { id: 50, name: '50' }, { id: 100, name: '100' }];

  //filter: FormControl;
  //filter_warnings: FormControl;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  //@ViewChild('confirmDelete') confirmDelete: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private homeService: HomeService
  ) {
    this.createNoteForm();
    this.createPubNoteForm();
  }

  openModal(targetModal: any) {
    this.confirmClearModal = this.modalService
      .open(targetModal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static'
      })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {

    if (this.credentialsService.isAuthenticated()) {
      this.isLogged = true;
      this.credentials = this.credentialsService.get_credentials();
      console.log(this.credentials);
    } else {
      this.router.navigate([this.route.snapshot.queryParams.redirect || '/login'], { replaceUrl: true });
    }

    //this.filter = new FormControl('');

  }

  private createNoteForm() {
    this.noteForm = this.formBuilder.group({
      mf_syn: [''],
      mf_res: '',
      mf_comment: '',
      appl_id: ''
    });
  }

  private createPubNoteForm() {
    this.pubnoteForm = this.formBuilder.group({
      _id: '', 
      mf_res: '',
      mf_comment: '',
      appl_id: '',
      pm_id: '',
      mf_syn: ''
    });
  }  


  isString(x: any) {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  search(text: string, data: any, column: string = '', exact: boolean = false) {
    let copyData = JSON.parse(JSON.stringify(data));

    //return copyData.filter((comp:any) => {
    //    const term = text.toLowerCase();
    //    return comp[column].toLowerCase().includes(term);
    //});

    const term = text.toLowerCase();
    if (column.length > 0) {
      console.log('Search in column: ' + column);
      return copyData.filter((comp: any) => {
        const term = text.toLowerCase();
	if(exact){
            return comp[column].toLowerCase() === term; 
	}else{	
            return comp[column].toLowerCase().includes(term);
        }
      });

    } else {
      console.log('Search in all columns');
      return copyData.filter((obj: any) => {
        return Object.keys(obj).reduce((acc, curr) => {
          //console.log(curr);
          return acc || (this.isString(obj[curr]) && obj[curr].toLowerCase().includes(term));
        }, false);
      });
    }
  }

  replaceData(params: any, data: any) {
    console.log('Start replaceData');
    let copyData = JSON.parse(JSON.stringify(data));
    let replaceData = {'context': true };
    let newData: any = [];

    if (replaceData) {
      copyData.forEach((element: any, index: any) => {
        newData[index] = Object.assign(element, replaceData);
      });
    }

    return newData;
  }

  onSort({ data, column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '') {
      return;
    }

    console.log('data:' + data + ',column:' + column + ',direction:' + direction);

    let cond = direction === 'asc' ? column : '-' + column;
    let sel_data = this[data];
    if (sel_data.data) {
      sel_data = sel_data.data;
    }

    sel_data.sort(this.dynamicSort(cond));

    return;
  }

  dynamicSort(property: any) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a: any, b: any) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  getSuggestions() {
    if (this.term_search.length > 2 && !this.isLoading) {

      this.show_pubs = false;	     
      this.isLoading = true;
      console.log(this.isLoading);

      let term = this.term_search;
      console.log(term);

      this.homeService
        .getTermBySync(term, 'diseases')
        .pipe(
          finalize(() => {
          })
        )
        .subscribe((json_term_data: any) => {
          let term_data = JSON.parse(json_term_data);
          if (term_data) {
            this.term_data['diseases'] = term_data;
            this.has_results = true;
          }
        });


       this.homeService
        .getTermBySync(term, 'targets')
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )		 
        .subscribe((json_term_data: any) => {
          let term_data = JSON.parse(json_term_data);
          if (term_data) {
            this.term_data['targets'] = term_data;
            this.has_results = true;
          }
        });

    }

    //console.log(this.term_data);
    console.log("Has results: " + this.has_results);
  }

  singleTermSearch(){
      console.log('Search only by specific phrase without syns');
      console.log('Using mode:' + this.single_term_search_mode);
      this.pubmed_pubs = {total: 0, data: []};

      if(this.single_term_search_mode == 'synonym'){
          this.appl_selected['search'] = this.adv_term_search;
          this.getPubs(this.adv_term_search, 0);
      }else{
         this.getSinglePub(this.adv_term_search, this.appl_selected['appl_id'], this.select_pm_source_type);  
      }

      return false;
  }

  resetTermSearch(){
      console.log('Search by appl_id again');
      this.adv_term_search = '';
      this.adv_term_search_index = null; 
      this.appl_selected['search'] = this.appl_selected['symbol'];
      this.getPubs(this.appl_selected['appl_id']);
      this.pubmed_pubs = {total: 0, data: []};
      return false;
  }

  setAnnotate__(pub: any, status: string){
      let annot_data = this.search(pub.pm_id, this.pubs.data, 'pm_id');
      if (annot_data.length > 0) {
          console.log('Found ' + annot_data.length + ' rows');
          console.log(annot_data);
	  annot_data = annot_data[0];

          this.pubs['data'].forEach((element: any, index: any) => {
              if(element.pm_id == annot_data['pm_id']){
                   this.pubs['data'][index]['context'] = status;
                   console.log('Set ' + pub.pm_id + 'context as ' + status);
	      } 
	  });

	  console.log(this.appl_selected);
	  let formData = [{_id: pub.pm_id,
		         appl_id: this.appl_selected['appl_id'], 
		         type: this.appl_selected['type'],
	                 context: status
	  }]; 

          this.homeService
          .setUserAnnot(formData)
          .pipe(
             finalize(() => {
             })
          )
          .subscribe((json_data: any) => {
          let annot_result = JSON.parse(json_data);
          if (annot_result) {
                console.log('Annotation added successfuly');
    		console.log(annot_result);
	  }

        });

          return true;
	  //let met_data = this.replaceData(params, annot_data);
      }

      //this.modalService.dismissAll();  
      return false;
  }

  setAnnotate(pub: any, status: string, datasource: string = 'pubs'){
      console.log('set status ' + status + ' for: ' + pub.pm_id + ' datasource: ' + datasource);
      let formData = [{_id: pub.pm_id,
                       appl_id: this.appl_selected['appl_id'],
                       type: this.appl_selected['type'],
                       context: status,
		       data_source: datasource    
      }];
     
       
      this.homeService
          .setUserAnnot(formData)
          .pipe(
             finalize(() => {
             })
        )
        .subscribe((json_data: any) => {
          let annot_result = JSON.parse(json_data);
          if (annot_result) {
                console.log('Annotation added successfuly');
                console.log(annot_result);
		if(datasource == 'pubs'){
                    console.log('Select datasource pubs');
         	    this.pubs['data'].forEach((element: any, index: any) => {
                       if(element.pm_id == pub.pm_id){
			   console.log('Found pm_id: ' + element.pm_id + ' in pubs and set status ' + status);     
                           this.pubs['data'][index]['context'] = status;
                       }
                   });
                }

                if(datasource == 'pubmed_pubs'){
                   this.pubmed_pubs['data'].forEach((element: any, index: any) => {
                       if(element.pm_id == pub.pm_id){
			   console.log('Found pm_id: ' + element.pm_id + ' in pubmed_pubs and set status ' + status);     
                           this.pubmed_pubs['data'][index]['context'] = status;
                       }
                   });
                }
          }

        });

      //this.modalService.dismissAll();
      return false;
  }

  setSynSearch(syn: string, synIndex: number){
      this.adv_term_search_index = synIndex;
      console.log("Set sel syn index: " + this.adv_term_search_index);
      console.log("Seet " + syn + " in search input");
      this.adv_term_search = '"' + syn + '"';
      this.syn_selected = syn; 
      //this.note_syn_selected = syn;
      this.noteForm.setValue({mf_syn: syn, mf_res: '', mf_comment: '', appl_id: this.appl_selected['appl_id']});
      console.log('syn_selected is: ' + this.noteForm.get('mf_syn'));
      this.single_term_search_mode = 'synonym'; 

      return false; 
  }

  onSelectColumn(select_data: any) {
    console.log('onSelectColumn');
    console.log(select_data);
    //let params = this.filterForm.value;
    this.appl_selected = select_data;
    //console.log(params['mf_filter']);
    //this.selectedColumnFilter = select_data;
    //this.modalService.dismissAll();
    //this.has_results = false;
    //this.term_data = [];
    //this.term_search_str = '';
    this.adv_term_search = ''; 
    this.syn_selected = '';   
    this.note_syn_selected = '';
    this.appl_selected['search'] = this.appl_selected['symbol'];
    this.getPubs(this.appl_selected.appl_id);
    this.pubmed_pubs = {total: 0, data: []};

    //this.getPubmedPubs(this.term_search);
  }

  loadPubsDiff(term: string, use_synonyms: number = 1){
      console.log('Load Pubs Diff');
      this.loaders.pubmed_pubs = 1;
      //base64_encode
     //this.homeService.getPubmedDiff(btoa(term), btoa(this.appl_selected['search']), use_synonyms)
       this.homeService.getPubmedDiff(this.base64_encode(term), this.base64_encode(this.appl_selected['search']), use_synonyms)
              .pipe(finalize(() => { this.loaders.pubmed_pubs = 0; }))
              .subscribe((pubsData: any) => {
                                        let pubs = JSON.parse(pubsData);
                                        this.pubmed_pubs = pubs;
                                        });
     this.show_pubmed_pubs = true;

  }

  getPubs(term: string, use_synonyms: number = 1){

     if(term.length < 3){
         return;
     }

     this.pubs = {total: 0, data: []};
     this.loaders.pubs = 1;
     this.homeService.getPubs(this.base64_encode(term), this.base64_encode(this.appl_selected['search']), use_synonyms)
              .pipe(finalize(() => { this.loaders.pubs = 0; }))
              .subscribe((pubsData: any) => {
                                        let pubs = JSON.parse(pubsData);
                                        this.pubs = pubs;
                                        });
     this.show_pubs = true;
  } 

  getSinglePub(pm_id: string, appl_id: string, pm_source_type: string){

      console.log('GetSinglePub with pm_id:' + pm_id + ', appl_id: ' + appl_id + ' pm_source_type: ' + pm_source_type);	    
      this.homeService.getPub(pm_id, appl_id)
              .pipe(finalize(() => {}))
              .subscribe((pubData: any) => {
                                        let pub = JSON.parse(pubData);
					console.log(pub);  
                                        this[pm_source_type] = pub;
                                        });

        this.select_pm_source_type = pm_source_type;
        console.log('Select ' + this.select_pm_source_type);

  }

  getSynNotes(term: string){

     if(term.length < 3){
         return;
     }

     this.synnotes = [];
     this.homeService.getUserSynNotes(this.base64_encode(term), this.base64_encode(this.appl_selected['search']))
              .pipe(finalize(() => { }))
              .subscribe((notesData: any) => {
                                        let notes = JSON.parse(notesData);
                                        this.synnotes = notes;
                                        });
     this.show_pubs = true;
  } 

  getPubmedPubs(term: string){

     if(term.length < 3){
         return;
     }

     this.pubmed_pubs = {total: 0, data: []};
     this.loaders.pubmed_pubs = 1;
     this.homeService.getPubmedPubs(this.base64_encode(term))
              .pipe(finalize(() => { this.loaders.pubmed_pubs = 0; }))
              .subscribe((pubsData: any) => {
                                        let pubs = JSON.parse(pubsData);
                                        this.pubmed_pubs = pubs;
                                        });
     this.show_pubmed_pubs = true;
  }

  getPubsStat(term: string, use_synonyms: number = 0){

     if(term.length < 3){
         return;
     }

     this.homeService.getPubs(this.base64_encode(term), this.base64_encode(this.appl_selected['search']), use_synonyms)
              .pipe(finalize(() => { }))
              .subscribe((pubsData: any) => {
                                        let pubs = JSON.parse(pubsData);
					if (pubs['total']) {return pubs['total'];}
					return 0;
  
	      });
  }

  showModifyForm() {
    if (!this.show) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  calc_syn_stat(){

      let synonyms = this.appl_selected['synonyms'];      
      if(synonyms.length < 1){
          return;
      }

      synonyms.forEach((element: any, index: any) => {
        console.log(element + "Index: " + index);
	//this.getPubsStat(element, 0, this.synonyms_stat[index]); 
        this.homeService.getPubs(this.base64_encode('"'+element+'"'), this.base64_encode(this.appl_selected['search']), 0)
              .pipe(finalize(() => { }))
              .subscribe((pubsData: any) => {
                                        let pubs = JSON.parse(pubsData);
                                        this.synonyms_stat[index] = pubs['total'];
                                        }); 
        
        });			
	
      console.log(this.synonyms_stat);     
      return false;
  }

  add_variants(input_list: any){

      let syns = JSON.parse(JSON.stringify(input_list));

      syns.forEach((element: any, index: any) => {
           ['-',' ', '_', '+', '/'].forEach((smb) => {
               let s = element.replace(smb, '');
               if (syns.indexOf(s) === -1) syns.push(s);
           }); 
      });

     return syns;
  }


  openAnnotForm(pm_source_type: string, pm_id: string, pub_index: number, content: any) {
       console.log('Open form to get abstract for ' + pm_id);
      
        if(this.pagination[pm_source_type] > 1){
	    if(pub_index == 0){ //First row
                pub_index = this.pagination[pm_source_type] * this.pageSize;
	    }else{
                pub_index = pub_index + (this.pagination[pm_source_type] * this.pageSize);
	    }
	}

        this.loaders[pm_source_type] = 1; 
        this.homeService.getPub(pm_id)
              .pipe(finalize(() => {this.loaders[pm_source_type] = 0}))
              .subscribe((pubsData: any) => {
                                        let pubs = JSON.parse(pubsData);
					this.search_words = this.add_variants(this.appl_selected['synonyms']);
					this.pub = pubs.data[0];
				        this.pub_index = pub_index;	
                                        console.log('Datasource:' + pm_source_type + ' Current page: ' + this.pagination[pm_source_type] + ', pub index: ' + this.pub_index);
					//this.appl_selected['abstract'] = pubs.data[0]['abstract'];
					//this.appl_selected['title'] = pubs.data[0]['title'];
                                        });

        this.select_pm_source_type = pm_source_type;
        console.log('Select ' + this.select_pm_source_type);
        console.log(this.search_words);					
	this.openModal(content);
  }

  openPubNoteForm(pm_source_type: string, pm_id: string, content: any, current_data: any) {
    
      if(current_data){	  
          console.log(current_data);		       
          this.pubnoteForm.setValue({
		  'mf_comment': current_data['mf_comment'],
		  'mf_res': current_data['mf_res'],
		  'appl_id': this.appl_selected['appl_id'],
		  'mf_syn': current_data['mf_syn'],
		  'pm_id': pm_id,
		  '_id': current_data['_id'] 
          }); 
      }else{
           this.pubnoteForm.setValue({
		  '_id': '',  
                  'mf_comment': '',
                  'mf_res': '',
                  'appl_id': this.appl_selected['appl_id'],
                  'pm_id': pm_id,
		  'mf_syn': this.appl_selected['search']
          });
      }

      this.select_pm_id = pm_id;
      this.select_pm_source_type = pm_source_type;
      console.log('Select ' + this.select_pm_source_type);
      this.openModal(content); 
  }

  navAnnotForm(pub_index: number, direction: string,  datasource: string){

      if(pub_index < 0 || !direction) {
         return;
      }   
   
        
      //let real_pub_index = this.pagination.pubs * pub_index; 
      console.log('datasource:' + datasource + ', navAnnotForm pubindex: ' + pub_index + ', page: ' + this.pagination.pubs);

      if(direction == 'next'){
	  pub_index = pub_index+1;     
      }

      if(direction == 'prev' && pub_index > 0){
	  pub_index = pub_index-1;    
      }
   
      console.log('pub index: ' + pub_index); 
      let pm_id = this[datasource]['data'][pub_index]['pm_id'];

      this.loaders['pub'] = 1;
      this.homeService.getPub(pm_id)
              .pipe(finalize(() => {this.loaders['pub'] = 0}))
              .subscribe((pubsData: any) => {
                                        let pubs = JSON.parse(pubsData);
                                        this.search_words = this.add_variants(this.appl_selected['synonyms']);
                                        this.pub = pubs.data[0];
					this.pub_index = pub_index;
                                        }); 


  }

  save_syn_note(content:any){

      let formData = this.noteForm.value;	  
      formData['appl_id'] = this.appl_selected['appl_id'];
      this.homeService
        .setUserSynNote(formData)
        .pipe(
          finalize(() => {
          })
        )
        .subscribe((json_data: any) => {
          let note_result = JSON.parse(json_data);
          if (note_result) {
                console.log('Note added successfuly');
                console.log(note_result);
          }

        });

        this.modalService.dismissAll();
  }

  save_pub_syn_note(datasource: string = 'pubs'){

      console.log('Save for ' + datasource);
      let formData = this.pubnoteForm.value;
      formData['pm_id'] = this.select_pm_id;
      formData['mf_term'] = this.appl_selected['search']
      formData['appl_id'] = this.appl_selected['appl_id']
      formData['data_source'] = datasource;
      //mf_term: this.appl_selected['search'],
       //mf_id: this.select_pm_id,

      console.log(formData);
      this.homeService
        .setUserPubNote(formData)
        .pipe(
          finalize(() => {
          })
        )
        .subscribe((json_data: any) => {
          let note_result = JSON.parse(json_data);
          if (note_result) {
                console.log('Note added successfuly');
                console.log(note_result);
                if(datasource == 'pubs'){
		   this.pubs['data'].forEach((element: any, index: any) => {
                       if(element.pm_id == formData['pm_id'] && formData['mf_term'] == this.appl_selected['search']){
                           this.pubs['data'][index]['note'] = formData;
                       }
                   });
                }

	        if(datasource == 'pubmed_pubs'){
                   this.pubmed_pubs['data'].forEach((element: any, index: any) => {
                       if(element.pm_id == formData['pm_id'] && formData['mf_term'] == this.appl_selected['search']){
                           this.pubmed_pubs['data'][index]['note'] = formData;
                       }
                   });
                }
          }
        });

        this.modalService.dismissAll();

  }

   del_pub_syn_note(datasource: string = 'pubs') {

    console.log('Del for ' + datasource);
    let formData = this.pubnoteForm.value;
    if (formData['_id'] == ''){
	console.log('Note id not found');      
        return;
    }

    let action_data = { action: 'delete', pub_note_id: formData['_id']};

    const data$ = this.homeService.delUserPubNote(action_data);
    data$
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        response => {
          console.log(response);
          let resp = JSON.parse(JSON.stringify(response));
          console.log(resp);

          if (!resp || resp['error'] && resp['error'].length > 0) {
            return false;
          } else {
              if(datasource == 'pubs'){
                   this.pubs['data'].forEach((element: any, index: any) => {
                       if(element.pm_id == formData['pm_id'] && formData['mf_term'] == this.appl_selected['search']){
                           this.pubs['data'][index]['note'] = formData;
                       }
                   });
                }

                if(datasource == 'pubmed_pubs'){
                   this.pubmed_pubs['data'].forEach((element: any, index: any) => {
                       if(element.pm_id == formData['pm_id'] && formData['mf_term'] == this.appl_selected['search']){
                           this.pubmed_pubs['data'][index]['note'] = formData;
                       }
                   });
                }
          }
        },
        error => {
          console.log(error);
          return false;
        }
      );

    this.modalService.dismissAll();
  }



  add_syn_note(content: any) {
    this.openModal(content);
  }

  onSwitchTabs(e: any) {
    if (e && e.nextId) {
      console.log(e.nextId);
    }
  }

  onPubTabChange(e: any){
      console.log(e);
      if(e && e.nextId){

          if(e.nextId == 'kolDiff' && !this.pubmed_pubs['data'].length){
              this.loadPubsDiff(this.appl_selected.appl_id);
              this.select_pm_source_type = 'pubmed_pubs';
	  }else{
              this.select_pm_source_type = 'pubs';
	  }

      }
   } 

  base64_encode(term: string){
      return btoa(unescape(encodeURIComponent(term)));
  }

  selectMaxSize($event: any) {
    console.log($event);
    //this.samplesPageSize = this.listSizes[$event];
    //console.log("Selected: " + this.samplesPageSize + " rows");
    this.pageSize = $event;
  }

  scrollTo(section: any) {
    document.querySelector('#' + section).scrollIntoView();
  }

  // Validates URL
  urlValidator(url: string) {
    if (!url.length) {
      return null;
    }
    const URL_REGEXP = /^(http?|https|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|ru|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    //url.markAsTouched();
    if (!URL_REGEXP.test(url)) {
      return null;
    }

    return true;
  }

}
